import { getTailwindScreenSizes } from 'utils/getTailwindScreenSizes';
import { useCallback } from 'react';

import { useWindowSize } from '../useWindowSize';

const DEFAULT_BREAKPOINT = 'narrow-xxx';

const SCREEN_SIZES = getTailwindScreenSizes();

export const useTailwindBreakpoint = () => {
  const { width = 0 } = useWindowSize();

  const currentBreakpoint =
    Object.keys(SCREEN_SIZES)
      .sort((key1, key2) => SCREEN_SIZES[key2] - SCREEN_SIZES[key1])
      .find((key) => width >= SCREEN_SIZES[key]) || DEFAULT_BREAKPOINT;

  const isScreenWiderOrEqualTo = useCallback(
    (breakpoint: string) => width >= SCREEN_SIZES[breakpoint],
    [width]
  );

  const isScreenNarrowerThan = useCallback(
    (breakpoint: string) => width < SCREEN_SIZES[breakpoint],
    [width]
  );

  const isScreenBetween = useCallback(
    (smallerBreakpoint: string, largerBreakpoint: string) =>
      width > SCREEN_SIZES[smallerBreakpoint] &&
      width < SCREEN_SIZES[largerBreakpoint],
    [width]
  );

  // width changes to non-zero when hook mounts, so then it is ready and returning proper breakpoint (not the default/starting one for 0 pixels)
  const isReady = width !== 0;

  return {
    currentBreakpoint,
    isReady,
    isScreenBetween,
    isScreenNarrowerThan,
    isScreenWiderOrEqualTo
  };
};
