import { Icon } from 'components/01-atoms/Icon';

import { CardProps, Card } from './Card';

export type CourseCardProps = {
  /**
   * Name of instructor of the course
   */
  instructor: string;
} & CardProps;

export const CourseCard = ({
  imageSrc,
  imageAlt,
  heading,
  description,
  href,
  target = '_self',
  instructor,
  hoverOverlayText,
  shouldLazyLoad = true
}: CourseCardProps) => (
  <Card
    imageSrc={imageSrc}
    imageAlt={imageAlt}
    heading={heading}
    description={description}
    href={href}
    target={target}
    hoverOverlayText={hoverOverlayText}
    shouldLazyLoad={shouldLazyLoad}
  >
    {instructor && (
      <div className="align-center flex">
        <Icon type="profile" size="xsmall" classes="mr-2" />
        <span className="u-text--sm">{instructor}</span>
      </div>
    )}
  </Card>
);
