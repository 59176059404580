import { useState, useCallback, useEffect, FC, Children } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTailwindBreakpoint } from 'hooks/useTailwindBreakpoint';
import { Icon } from 'components/01-atoms/Icon';
import { OptionsType } from 'embla-carousel/components/Options';

export type CarouselProps = {
  /**
   * The number of child components to be displayed at a time
   */
  viewLimit: number;
  /**
   * The number of child components to be displayed at a time on mobile screens
   */
  mobileViewLimit?: number;
  /**
   * Defines the percentage of the partial slide to display, if showPartialNextSlide is set to true.
   * This should be a number between 0 and 1
   */
  partialNextSlidePercentage?: number;
  /**
   * The color of the previous and next buttons
   */
  buttonColor?: string;
  /**
   * Classes on top-level component div; px-11 == makes arrows look like OUTSIDE container (e.g. watch); px-22 == looks like INSIDE (e.g. learn)
   */
  classes?: string;
  /**
   * Classes on embla viewport component div; used if there is a drop shadow, hover scale state, etc. on card that isn't cut off by overflow-hidden
   */
  viewportClasses?: string;
  /**
   * Determines whether or not a hover state is shown on the buttons.
   */
  showBtnHover?: boolean;
  /**
   * Classes that go on the Icon on hover of the button, such as background color. Only applicable when showBtnHover is true.
   */
  buttonHoverClasses?: string;
  /**
   * Boolean that specifies what type of carousel
   */
  freeVideoCarousel?: boolean;
  /**
   * Number that determines where the carousel starts
   */
  startIndex?: number;
};

export const Carousel: FC<CarouselProps> = ({
  viewLimit,
  mobileViewLimit = viewLimit,
  partialNextSlidePercentage = 0.8,
  children,
  buttonColor = 'black',
  classes = '',
  viewportClasses = 'px-1 py-2',
  showBtnHover = true,
  freeVideoCarousel = false,
  startIndex,
  buttonHoverClasses = 'bg-[#0D5267]'
}) => {
  // converts single and/or falsy child values into an array
  const slides = Children.toArray(children);

  const { isScreenNarrowerThan, isScreenWiderOrEqualTo } =
    useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow-x');
  const currentViewLimit = isMobile ? mobileViewLimit : viewLimit;

  // Don't output arrows unless we are tablet+ and there are more slides than view limit
  const showCarouselArrows =
    isScreenWiderOrEqualTo('narrow-x') && slides.length > currentViewLimit;

  let emblaParams: Partial<OptionsType>;
  if (freeVideoCarousel === false) {
    emblaParams = {
      dragFree: true,
      draggable: isMobile,
      align: 'start',
      slidesToScroll: currentViewLimit
    };
  } else {
    emblaParams = {
      dragFree: true,
      draggable: isMobile,
      align: 'start',
      startIndex
    };
  }

  const [emblaViewport, embla] = useEmblaCarousel(emblaParams);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
  }, [embla]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const handlePreviousClick = useCallback(
    () => embla && embla.scrollPrev(),
    [embla]
  );

  const handleNextClick = useCallback(
    () => embla && embla.scrollNext(),
    [embla]
  );

  const handleSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const slidesVisible = isMobile
    ? currentViewLimit + partialNextSlidePercentage
    : currentViewLimit;
  const slideWidth = 100 / slidesVisible;

  // handles the hiding and showing of the navigation arrows
  useEffect(() => {
    if (!embla) return;
    embla.on('select', handleSelect);
    handleSelect();
  }, [embla, handleSelect]);

  return (
    <div className={`${classes} narrow-x:px-22`}>
      <div className="embla relative narrow-x:-mx-11">
        <div
          className={`embla-viewport ${viewportClasses} w-full overflow-hidden`}
          ref={emblaViewport}
        >
          <div className="embla-container -ml-6 flex narrow-x:-ml-7">
            {/* eslint-disable react/no-array-index-key */}
            {slides.map((slide, index) => (
              <div
                key={index}
                className="relative pl-6 narrow-x:pl-7"
                style={{
                  minWidth: `${slideWidth}%`,
                  maxWidth: `${slideWidth}%`
                }}
              >
                {slide}
              </div>
            ))}
            {/* eslint-enable react/no-array-index-key */}
          </div>
        </div>

        {showCarouselArrows && (
          <>
            <button
              className="group absolute top-1/2 -translate-y-1/2 rotate-180 transform narrow-x:-left-11"
              onClick={handlePreviousClick}
              disabled={!prevBtnEnabled}
              aria-label="Previous Button"
              type="button"
            >
              {prevBtnEnabled && (
                <>
                  {showBtnHover && (
                    <div
                      className={`absolute top-1/2 hidden h-[48px] w-[48px] -translate-y-1/2 transform cursor-pointer rounded-full group-hover:inline-block ${buttonHoverClasses}`}
                      style={{ left: '-20px' }}
                    />
                  )}
                  <Icon
                    strokeColor={`${showBtnHover ? '' : buttonColor}`}
                    strokeWidth="0.5"
                    color={`${showBtnHover ? '' : buttonColor}`}
                    type="chevron"
                    size="small"
                    classes={`${
                      showBtnHover
                        ? 'relative fill-current stroke-current text-network-black group-hover:text-network-white'
                        : ''
                    }`}
                  />
                </>
              )}
            </button>
            <button
              className="group absolute top-1/2 -translate-y-1/2 transform narrow-x:-right-11"
              onClick={handleNextClick}
              disabled={!nextBtnEnabled}
              aria-label="Next Button"
              type="button"
            >
              {nextBtnEnabled && (
                <>
                  {showBtnHover && (
                    <div
                      className={`absolute top-1/2 hidden h-[48px] w-[48px] -translate-y-1/2 transform cursor-pointer rounded-full group-hover:inline-block ${buttonHoverClasses}`}
                      style={{ right: '-16px' }}
                    />
                  )}
                  <Icon
                    strokeColor={`${showBtnHover ? '' : buttonColor}`}
                    strokeWidth="0.5"
                    color={`${showBtnHover ? '' : buttonColor}`}
                    type="chevron"
                    size="small"
                    classes={`${
                      showBtnHover
                        ? 'relative fill-current stroke-current text-network-black group-hover:text-network-white'
                        : ''
                    }`}
                  />
                </>
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Carousel;
