import ReactJWPlayer from 'react-jw-player';

export type VideoPlayerProps = {
  /** A function that is called when the video is played */
  onPlay?: () => void;
  /** A function that is called when the video is paused */
  onPause?: () => void;
  /** A function that is called when the video player is mounted and ready */
  onReady?: () => void;
  /** A function that is called when the video is loaded and ready to play */
  onVideoLoad?: () => void;
  /** A function that is called when the video ends */
  onOneHundredPercent?: () => void;
  /** A unique Id for the player instance. Used to distinguish the container divs. */
  playerId: string;
  /** Show Control Bar */
  classes?: string;
  /** className for container div */
  className?: string;
  /** Link to a valid JW Player script. */
  playerScript: string;
  /** Link to a valid JW Player playlist or playlist array. playlist OR file is required so JWPlayer knows what to play. Cool tip: JW Player automatically generates JSON feeds for individual videos if you use the video id. You can use this to get meta data on the videos without loading an actual playlist. */
  playlist?: string;
  /** Link to a live stream file. playlist OR file is required so JWPlayer knows what to play. */
  file?: string;
  /** Determines whether the player starts automatically or not. */
  isAutoPlay?: boolean;
  /** URL to a poster image to display before playback starts. Overrides the poster image already set in JWPlayer. */
  image?: string;
  /** The video width */
  width?: string;
  /** Should we hide video controls. (For trailers) */
  controls?: boolean;
  /** Determines whether the player starts muted or not. */
  isMuted?: boolean;
  /** aspect ratio for the video */
  aspectRatio?: string;
  /** Defines the display strategy of the media inside of a video player at different heights/aspect-ratios */
  stretching?: string;
  /** To enable casting, add an empty cast object in your setup. */
  cast?:
    | false
    | {
        customAppId?: string;
      };
  /** Should video loop */
  repeat?: boolean;
  //* * Allows the use of multiple player scripts on a single page */
  useMultiplePlayerScripts?: boolean;
  /** Function that generates a preroll ad URL */
  generatePrerollUrl?: () => string;
  /** An optional object containing properties to be applied directly to the JW Player instance */
  customProps?: {
    advertising?: unknown;
  };
};

export const VideoPlayer = ({
  playerId,
  playerScript,
  playlist,
  file,
  classes,
  className = '',
  width = '100%',
  isAutoPlay,
  onPlay,
  onPause,
  onReady,
  onVideoLoad,
  onOneHundredPercent,
  controls = true,
  isMuted = false,
  image,
  aspectRatio = '16:9',
  stretching = 'uniform',
  cast = {},
  repeat = false,
  useMultiplePlayerScripts = false,
  generatePrerollUrl,
  customProps = {}
}: VideoPlayerProps): JSX.Element => {
  const customPropsComplete = {
    width,
    controls,
    repeat,
    cast,
    aspectratio: aspectRatio,
    stretching,
    responsive: true,
    intl: {
      en: {
        errors: {
          protectedContent:
            'Due to geographic licensing restrictions, you are not permitted to view this content in your location.'
        }
      }
    },
    ...customProps
  };
  const jwPlayerProps = {
    playerId,
    playerScript,
    playlist,
    file,
    isAutoPlay,
    onPlay,
    onPause,
    onReady,
    onVideoLoad,
    onOneHundredPercent,
    image,
    isMuted,
    customProps: customPropsComplete,
    className,
    useMultiplePlayerScripts,
    generatePrerollUrl
  };

  return (
    <div className={classes}>
      <ReactJWPlayer {...jwPlayerProps} />
    </div>
  );
};
