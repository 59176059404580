import { cloneElement, MouseEventHandler } from 'react';

import { IconProps } from './Icon';

export type ButtonProps = {
  classes?: string;
  disabled?: boolean;
  href?: string;
  icon?: IconProps;
  onClick?: MouseEventHandler<HTMLElement> | VoidFunction;
  outline?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  target?: '_blank' | '_parent' | '_self' | '_top';
  text?: string;
  theme?: 'primary' | 'secondary' | 'alert';
  type?: 'button' | 'reset' | 'submit';
};

export const Button = ({
  classes = '',
  disabled = false,
  href = '',
  icon,
  onClick = () => null,
  outline = false,
  size,
  target = '_self',
  text,
  theme,
  type = 'button'
}: ButtonProps) => {
  const ClonedIcon = () =>
    cloneElement(
      icon as JSX.Element,
      {
        ...(disabled && {
          classes: `${(icon as JSX.Element).props.classes} opacity-50`.trim()
        })
      },
      null
    );

  const getThemeClass = () => {
    if (theme) {
      if (outline) {
        return `btn-${theme}-outline`;
      }
      return `btn-${theme}`;
    }

    return 'btn-primary';
  };

  const baseClasses = 'inline-flex items-center justify-center';
  const buttonSizeClass = size ? ` btn-${size}` : ' btn-sm';
  const combinedClasses =
    `${baseClasses} ${getThemeClass()}${buttonSizeClass} ${classes}`.trim();

  if (href) {
    return (
      <a
        className={combinedClasses}
        href={href}
        target={target}
        onClick={onClick}
      >
        {icon && <ClonedIcon />}
        {!text ? <span>&nbsp;</span> : <span>{text}</span>}
      </a>
    );
  }

  return (
    /* eslint-disable react/button-has-type */
    <button
      className={combinedClasses}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {icon && <ClonedIcon />}
      {!text ? <span>&nbsp;</span> : <span>{text}</span>}
    </button>
  );
};
