import { StrictLinkConfig } from 'types/WebMicrofrontends';

export const routes = {
  home: () => `/`,
  course: (courseName: string) => `/${courseName}`,
  courseIntroduction: (courseName: string) => `/${courseName}`,
  lessonDetail: (courseName: string, lessonName: string) =>
    `/${courseName}/${lessonName}`,
  standaloneLesson: (lessonName: string) => `/lessons/${lessonName}`,
  category: (categoryId: string) => `/category/${categoryId}`,
  error: () => `/notfound`,
  bookmarks: () => '/bookmarks',
  feed: () => '/feed',
  memberExclusives: () => '/member_exclusives',
  myBenefits: () => '/my_benefits'
};

export const navLinks: StrictLinkConfig[] = [
  {
    text: 'Home',
    href: routes.home(),
    target: '_self'
  },
  {
    text: 'All',
    href: routes.category('all'),
    target: '_self'
  }
];
