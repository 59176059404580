import { FC } from 'react';
import { Image } from 'components/01-atoms/Image';
import { useTailwindBreakpoint } from 'hooks/useTailwindBreakpoint';
import { H2, H3, H4 } from 'components/01-atoms/Heading';

export interface CardProps {
  /**
   * Source URL of image to display at top of card.  Image will display
   * within a 16x9 aspect ratio container, cropped to fit
   */
  imageSrc: string;
  /**
   * Alternative text for the image
   */
  imageAlt: string;
  /**
   * Text to display as heading of the course card
   */
  heading: string;
  /**
   * Type of heading to display.  Default is h3
   */
  headingType?: 'h2' | 'h3' | 'h4';
  /**
   * Text to display as the description
   */
  description?: string;
  /**
   * URL to go to when card is clicked anywhere
   */
  href: string;
  /**
   * How should the link open?
   */
  target?: '_blank' | '_parent' | '_self' | '_top';
  /** Additional class names */
  classes?: string;
  /**
   * Text that will appear over the image on hover
   */
  hoverOverlayText?: string;
  /**
   * Lazy load = whether image should lazy load or not
   */
  shouldLazyLoad?: boolean;
}

const headingMap = {
  h2: H2,
  h3: H3,
  h4: H4
};

export const Card: FC<CardProps> = ({
  imageSrc,
  imageAlt,
  heading,
  headingType = 'h3',
  description,
  target = '_self',
  href,
  classes = '',
  children,
  hoverOverlayText = '',
  shouldLazyLoad = true
}) => {
  const HeadingComponent = headingMap[headingType];

  const { isScreenWiderOrEqualTo } = useTailwindBreakpoint();

  return (
    <a href={href} target={target}>
      <div
        className={`mfe group flex h-full flex-col overflow-hidden rounded-xl shadow-md narrow-xx:rounded ${classes}`.trim()}
      >
        <div className="aspect-h-9 aspect-w-16 w-full overflow-hidden bg-network-grey">
          <div className="u-text--uppercase-lg z-10 flex items-center justify-center bg-network-black bg-opacity-0 font-400 text-network-white text-opacity-0 transition duration-300 group-hover:bg-opacity-50 group-hover:text-opacity-100">
            {hoverOverlayText}
          </div>
          <Image
            src={imageSrc}
            alt={imageAlt}
            classes="w-full h-full object-cover"
            shouldLazyLoad={shouldLazyLoad}
          />
        </div>
        <div className="flex flex-auto flex-col justify-between p-3 pt-4 text-left font-network-font-1 text-network-black group-hover:text-network-grey-darker narrow-xx:pt-3">
          <div>
            <HeadingComponent
              headingText={heading}
              classes="h6 cursor-pointer"
            />
            {description && isScreenWiderOrEqualTo('narrow-xx') && (
              <p className="u-text--sm mt-2 line-clamp-3">{description}</p>
            )}
          </div>
          {children && <div className="mt-5 narrow-xx:mt-10">{children}</div>}
        </div>
      </div>
    </a>
  );
};
